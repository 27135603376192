import config from "@api/config";
import httpNextore from "@api/nextore/httpNextore";

const encodeQuery = require('querystring');

export const _get = (URL, bufferLink = null) => {
  return httpNextore
    .get(URL, _handleConfig(bufferLink))
    .then(response => {
      return new Promise((resolve) => {
        resolve(response);
      });
    }).catch((error) => {
      return Promise.reject(error);
    })
    ;
}

export const _post = (URL, data, encode = true) => {
  const encodedData = encode ? encodeQuery.stringify(data) : data;

  return httpNextore.post(URL, encodedData)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    ;
}

export const _put = (URL, data, encode = true) => {
  const encodedData = encode ? encodeQuery.stringify(data) : data;

  return httpNextore.put(URL, encodedData)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
    ;
}

export const _delete = (URL) => {
  return httpNextore.delete(URL)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
  ;
}

const _handleConfig = (bufferLink) => {
  return bufferLink !== null ?
    {
      responseType: config.array_buffer,
      responseEncoding: config.binary_encode,
      headers: {
        'Content-Type': config.pdf_contentType,
      }
    } :
    {
      headers: {
        'Content-Type': config.json_contentType,
      }
    }
    ;
}
