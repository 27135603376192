import {_delete, _get, _post, _put, clubId} from "@api/doinsport/services/httpService";

export const URI_BLOCK_PRICE = 'clubs/playgrounds/timetables/blocks/prices';

const participantURL = (blockPriceId) => `/clubs/playgrounds/timetables/blocks/prices/${blockPriceId}/participant-categories`;

export const postPrice = (price) => _post(URI_BLOCK_PRICE, price);

export const getPricesPlayground = (playgroundId, activityId, filters = []) => {
  let activityTypes = '';

  for (const filter of filters) {
    activityTypes += '&activityType[]=' + filter;
  }

  return _get(URI_BLOCK_PRICE + '?playground.id=' + playgroundId + '&availableOnBackOffice=true&itemsPerPage=100&page=1&activity.id=' + activityId + activityTypes)
};

export const getOptions = (priceId) => _get(URI_BLOCK_PRICE + '/' + priceId + '/options');

export const putPrice = (price) => _put(URI_BLOCK_PRICE + '/' + price.id, price);

export const getPrice = (id) => _get(URI_BLOCK_PRICE + '/' + id);

export const getPriceVariations = (id, itemsPerPage) => _get(URI_BLOCK_PRICE + '/variations?blockPrice.id=' + id + '&itemsPerPage=' + itemsPerPage);

export const getPricePaymentTokens = (id) => _get('clubs/playgrounds/timetables/blocks/payment-token-prices?blockPrice.id=' + id + '&itemsPerPage=500');

export const getPriceCategories = (id, itemsPerPage = 500) => _get(participantURL(id) + '?itemsPerPage=' + itemsPerPage);

export const deletePrice = (id) => _delete(URI_BLOCK_PRICE + '/' + id);

export const getPrices = ((itemsPerPage, page, activities, customClubId = null) => {
  let filters = '';

  for (const act of activities) {
    filters += "&activity.id[]=" + act.split('/activities/')[1];
  }
  const clubFilter = customClubId ? customClubId : clubId;

  return _get(URI_BLOCK_PRICE + '?club.id=' + clubFilter + '&itemsPerPage=' + itemsPerPage + '&page=' + page + filters);
});

export const getPricesByFilter = ((itemsPerPage = 500, page = 1, search = '') => {
  return _get(URI_BLOCK_PRICE + '?club.id=' + clubId + '&itemsPerPage=' + itemsPerPage + '&page=' + page + '&search=' + search);
});

export const getExculdedPrices = ((itemsPerPage, page, activities, excludedPlaygrounds, types = []) => {
  let excludedPlaygroundsfilters = '';

  let filters = '';

  for (const act of activities) {
    filters += "&activity.id[]=" + act.split('/activities/')[1];
  }

  let activityTypes = '';

  for (const filter of types) {
    activityTypes += '&activityType[]=' + filter;
  }

  return _get(URI_BLOCK_PRICE + '?club.id=' + clubId + excludedPlaygroundsfilters + '&itemsPerPage=' + itemsPerPage + '&page=' + page + filters + activityTypes);
});

export const getPlaygroundPrices = ((itemsPerPage = 500, page = 1, playgroundId, filters = []) => {
  let activityTypes = '';

  for (const filter of filters) {
    activityTypes += '&activityType[]=' + filter;
  }

  return _get(URI_BLOCK_PRICE + '?playgrounds.id=' + playgroundId + '&itemsPerPage=' + itemsPerPage + '&page=' + page + activityTypes);
});
export const getPlaygroundPricesFilteredByActivities = ((itemsPerPage, page, playgroundId, activities) => {
  let filters = '';

  for (const act of activities) {
    filters += "&activity.id[]=" + act.split('/activities/')[1];
  }

  return _get(URI_BLOCK_PRICE + '?playground.id=' + playgroundId + filters + '&itemsPerPage=' + itemsPerPage + '&page=' + page);
});

export const getBlockPricesByPlaygroundId = (playgroundId) => _get(URI_BLOCK_PRICE + '?playground.id=' + playgroundId + '&itemsPerPage=500&page=1');

