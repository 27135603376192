export const QUOTE_PATH = "quote";

export default [
  {
    path: QUOTE_PATH,
    name: 'quote',
    props: true,
    component: () => import('@views/quote/Index')
  },
  {
    path: QUOTE_PATH + '/new',
    name: `${QUOTE_PATH}.new`,
    component: () => import('@views/caisse/Index')
  },
  {
    path: QUOTE_PATH + '/edit/:id',
    name: `${QUOTE_PATH}.edit`,
    component: () => import('@views/caisse/Index')
  }
]

