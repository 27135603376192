import {_get, _put, clubId} from "@api/doinsport/services/httpService";

const URL = 'clubs/';

export const getCurrentClub = () => _get(URL + clubId);

export const getClubByIri = (iri) => _get(iri);

export const putClub = (id, club) => _put(URL + clubId, club);

export const getParams = (dateString) => _get(URL + clubId + '/planning-parameter?date=' + dateString+'&partial=true');

export const getOrderConfiguration = (id) => _get(URL + `${id}/order-configuration`);

export const putOrderConfiguration = (id, data) => _put(URL + `order-configurations/${id}`, data);