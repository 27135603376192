import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=5879612f&scoped=true"
var script = {}
import style0 from "@/assets/scss/auth/auth.scss?vue&type=style&index=0&id=5879612f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5879612f",
  null
  
)

export default component.exports