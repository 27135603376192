import store from "@plugins/store";
import {cloneData} from "@/utils/form";

export const formatRegisters = (data) => {
  const array = [];

  for (const key of Object.keys(data)) {
    const register = cloneData(data[key][0]);

    register.club = store.getters['auth/currentClub']['@id'];
    array.push(register);
  }

  return array;
}

export const getUniqueValues = (array) => (
  array.reduce((accumulator, currentValue) => (
    accumulator.includes(currentValue) ? accumulator : [...accumulator, currentValue]
  ), [])
)

export const objectToArray = (object) => {
  let array = [];

  for (const el of Object.keys(object)) {
    let obj = {};

    obj[el] = object[el];

    array.push(obj);
  }

  return array;
}
