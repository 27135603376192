export default {
  getVariations: (state) => {
    return state.variations;
  },
  getIsLoadedVariations: (state) => {
    return state.isLoadedVariations;
  },
  getLoadedParticipants: (state) => {
    return state.loadedParticipants;
  },
  getSelectedTab: (state) => {
    return state.selectedTab;
  },
}
