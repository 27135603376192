export default [
  {
    path: 'news',
    name: 'news',
    component: () => import('@views/news/Index'),
  },
  {
    path: 'news/new',
    name: 'new_news',
    component: () => import('@views/news/createOrUpdate/Index'),
  },
  {
    path: 'news/update/:id',
    name: 'news_update',
    component: () => import('@views/news/createOrUpdate/Index'),
  }
];
