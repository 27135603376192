const path = 'reports';

export default [
  {
    path: path + '/payment',
    name: 'payment',
    component: () => import('@views/reports/payment/Index'),
  },
  {
    path: path + '/subscriptions',
    name: 'subscription',
    component: () => import('@views/reports/subscriptions/Index'),
  },
  {
    path: path + '/credits',
    name: 'credits-report',
    component: () => import('@views/reports/credit/Index'),
  }
];
