import store from "@plugins/store";

export default {
  setQuoteConfiguration: (state, payload) => state.quoteConfiguration = payload,
  setOrderQuote: (state, payload) => {
    store.commit('nextore/setOrderQuote', payload);
    state.orderQuote = payload
  },
  setQuoteItems: (state, payload) => state.quoteItems = payload,
  setQuoteBookings: (state, payload) => state.quoteBookings = payload,
  setQuote: (state, payload) => state.quote = payload,
  addQuoteItem: (state, payload) => state.quoteItems.push(payload),
  updateQuoteItem: (state, payload) => {
    const findItemToUpdate = state.quoteItems.find(el => el.id === payload.id);
    state.quoteItems[state.quoteItems.indexOf(findItemToUpdate)] = payload;
  },
  reset: (state)=> {
    state.orderQuote = null;
    state.quoteItems = [];
    state.quoteConfiguration = null;
  },
}
