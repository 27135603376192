
let events = localStorage.getItem('access-events');
if (events === null || events === undefined) {
  events = [];
} else {
  events = JSON.parse(events);
}

export default {
  events: events
}
