export default {
  setVariations: (state, payload) => {
    state.variations = payload;
  },
  setIsLoadedVariations: (state, payload) => {
    state.isLoadedVariations = payload;
  },
  setLoadedParticipants: (state, payload) => {
    state.loadedParticipants = payload;
  },
  setSelectedTab: (state, payload) => {
    state.selectedTab = payload;
  },
}
