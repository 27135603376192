<template>
  <form v-if="!validation_check" class="login-right-form" @submit.prevent="submit">
      <div class="wrap-img">
        <img class="login-left-logo" src="@/assets/images/doinsport/login.svg" />
      </div>
    <div class="login-right-form-header">
      <h2>{{ $t('auth.login.title') }}</h2>
    </div>
    <div class="form-group">
      <input
        v-model="email"
        type="email"
        class="form-control login-input-border"
        :placeholder="$t('auth.login.email')"
        :disabled="intended"
        :class="email_error ? 'invalid' : ''"
        @input="checkFormat('email')"
      />
    </div>
    <div v-if="intended" class="form-group">
      <label>{{ $t('auth.login.confirmation-code') }}</label>
      <input :class="code_error ? 'invalid' : ''" class="form-control login-input-border code-input" type="text" placeholder="_ _ _ _" name="code" v-model="code" />
    </div>

    <div class="form-group login-right-form-submit --flex">
      <button
        type="submit"
        :disabled="loading"
      >
        {{ $t('auth.login.validate') }}
        <i v-if="loading" class="fa fa-refresh fa-spin"></i>
      </button>
      <router-link :to="{ path: '/auth/login' }">
        <button
          type="button"
          class="cancel"
        >
          {{ $t('auth.login.cancel') }}
        </button>
      </router-link>
    </div>
  </form>
  <form v-else class="login-right-form" @submit.prevent="setPassword">
    <div class="login-right-form-header">
      <span>{{ $t('auth.login.choose-password') }}</span>
    </div>
    <div class="form-group">
      <input
        v-model="password"
        type="password"
        class="form-control login-input-border"
        :placeholder="$t('auth.login.password')"
        :class="password_error ? 'invalid' : ''"
        @input="checkFormat(password)"
      />
    </div>
    <div class="form-group">
      <input
        v-model="passwordConfirm"
        type="password"
        class="form-control login-input-border"
        :placeholder="$t('auth.login.password-confirm')"
        :class="passwordConfirm_error ? 'invalid' : ''"
        @input="checkFormat('passwordConfirm')"
      />
    </div>

    <div class="form-group login-right-form-submit --flex">
      <button
        type="submit"
        :disabled="loading"
      >
        {{ $t('auth.login.password-edit') }}
        <i v-if="loading" class="fa fa-refresh fa-spin"></i>
      </button>
      <router-link :to="{ path: '/auth/login' }">
        <button
          type="button"
          class="cancel"
        >
          {{ $t('auth.login.cancel') }}
        </button>
      </router-link>
    </div>
  </form>
</template>

<script>
import jwt_decode from "jwt-decode";
import {
  userExist,
  requestValidationIntents,
  processValidationCheck,
  editUserClientsPassword
} from "@api/doinsport/services/auth/auth.api";
import email from "@/validation/rules/email";

export default {
  name: "login",
  data() {
    return {
      email: "",
      code: "",
      password: "",
      passwordConfirm: "",
      loading: false,
      intended: false,
      email_error: false,
      code_error: false,
      password_error: false,
      passwordConfirm_error: false,
      validation_check: false,
      validation: null,
    };
  },
  methods: {
    submit() {
      if(this.email_error) {
        return;
      }
      if (this.intended) {
        return this.validate();
      }

      this.loading = true;

      userExist(this.email).then((payload) => {
        if (payload.data.exist) {
          requestValidationIntents(this.email).then(() => {
            this.intended = true;
          }).finally(() => {
            this.loading = false;
          })
        } else {
          this.$flash(null, this.$t('auth.login.email-not-found'))
          this.email_error = true;
        }
      }).catch((error) => {
        this.$flash(null, this.$t('auth.login.email-not-found'))
        this.email_error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    validate() {
      this.loading = true;
      processValidationCheck(this.email, this.code).then((response) => {
        this.validation = response.data;
        this.validation_check = true;
      }).catch(() => {
        this.code_error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    setPassword() {
      if(this.password_error || this.passwordConfirm_error) {
        return;
      }
      this.loading = true;
      const user = jwt_decode(this.validation.token);
      editUserClientsPassword(user.userId, this.password, this.validation.token).then(() => {
        this.$store.dispatch('auth/login', {username: this.email, password: this.password})
          .then(() => {
            this.loading = false;
            this.password = "";
            this.passwordConfirm = "";
            this.email = "";

            this.$router.push('/');

            setTimeout(() => {
              this.$router.go(0);
            }, 1500);
          }).finally(() => {
            this.loading = false;
          })
      })
    },
    checkFormat(type) {
      switch(type) {
        case 'email':
          if (this[type].length === 0 || !email.validate(this[type], '')) {
            this.email_error = true;
          } else {
            this.email_error = false;
          }
          break;
        case 'password':
          if (this[type].length === 0 || this[type].length < 8 || (this[type].length > 0 && this.passwordconfirm.length > 0 && this[type] !== this.passwordconfirm)) {
            this.password_error = true;
          } else {
            this.password_error = true;
          }
          break;
        case 'passwordConfirm':
          if (this[type].length === 0 || this[type].length < 8 || (this[type].length > 0 && this.password.length > 0 && this[type] !== this.password)) {
            this.passwordConfirm_error = true;
          } else {
            this.passwordConfirm_error = false;
          }
          break;
      }
    }
  },
};
</script>