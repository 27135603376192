const path = 'shop';

export default [
  {
    path: path,
    name: path,
    component: () => import('@views/shop/Index'),
    meta: {
      title: 'data.menu.shop.meta.title',
    },
  },
  {
    path: path + '/new',
    name: 'new_product',
    component: () => import('@views/shop/createOrUpdate/Index'),
  },
  {
    path: path + '/update/:id',
    name: 'update_product',
    component: () => import('@views/shop/createOrUpdate/Index'),
  }
];
