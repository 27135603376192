import {ALL_EVENTS} from "@/classes/doinsport/EndPoint";

export default {
  updateCheckBoxAll: (state, payload) => {
    let key;

    for (key = 0; key < ALL_EVENTS.length; key++) {
      if (ALL_EVENTS[key].findIndex(el => el === payload.value) > -1) {
        break;
      }
    }

    state.configuration[key].attrs[0].checked = ALL_EVENTS[key].filter(elt => !payload.events.includes(elt)).length === 0;
  },
}
