import {_get} from "@api/nextore/services/httpNextoreService";

const ACTIVE_REGISTERS_URL = 'registersdetails?status=open';

const REGISTER_DETAILS_URL = `${ACTIVE_REGISTERS_URL}&user_id=`;

export const getOpenRegistriesByUserId = (id) => _get(`${REGISTER_DETAILS_URL}${id}`);

export const getAllOpenRegistries = (id) => _get(`${ACTIVE_REGISTERS_URL}`);

export const getZTicketContent = (id, type = 0) => _get(`print_register?rid=${id}&noprint=${type}`);
