export default {
  setSidebar: (state, payload) => {
    localStorage.setItem('caisse-sidebar', payload);
    state.sidebar = payload;
  },
  setAutoPrint: (state, payload) => {
    localStorage.setItem('auto-print', payload);
    state.autoPrint = payload;
  },
  setNextoreProducts: (state, payload) => {
    state.nextoreProducts = payload;
  },
}
