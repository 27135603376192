import { hydrate } from "@/utils/form";
export const EMPTY_ITEM = 'empty';

export default class Product {
  id = null;
  name =	null;
  quantity = null;
  price = null;
  isBlockPrice = false;
  blockPriceId = null;
  taxeRate = null;
  booking = EMPTY_ITEM;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
}
