import {_get, _post} from "@api/nextore/services/httpNextoreService";
import {BLOB_CONTENT_TYPE} from "@api/config";

const URL = 'sales';
const LIGHTSALES_URL = 'lightsales';
const CANCEL_SALE_URL = 'cancelsale';
const ALL_CANCELED_SALE_URL = 'cancelsales';
const BILL_URL = 'bills';

export const addSale = (sale) => {
  const params = new URLSearchParams()
  params.append('data', JSON.stringify(sale))

  return _post(URL, params, false)
}
export const getSalesHistory = (id) => _get(URL + '?register_id=' + id + "&order=desc");

export const getLightSales = (id = null) => {
  const customerFilter = id ? '?customer_id=' + id : '';

  return _get(`${LIGHTSALES_URL}${customerFilter}`);
}

export const getCancelSale = (reference) => _get(CANCEL_SALE_URL + '?reference=' + reference);

export const getAllCanceledSales = (register_id) => _get(ALL_CANCELED_SALE_URL + '?register_id=' + register_id);

export const getBill = (id) => _get(BILL_URL + '/' + id, BLOB_CONTENT_TYPE);

export const getLightSalesPerClient = (id) => _get(
  LIGHTSALES_URL +
  '?customer_id=' + id
);

export const getAccountSales = () => _get('account_sales');

export const getTaxRates= () => _get('tax_rates');

