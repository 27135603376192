const path = 'lessons';

export default [
  {
    path: path,
    name: 'lessons',
    component: () => import('@views/lessons/Index'),
    meta: {
      title: 'data.menu.lessons.meta.title',
    }
  },
]
