const path = 'webhooks';

export default [
    {
        path: path,
        name: 'webhooks',
        component: () => import('@views/top-bar/webhooks/Index')
    },
    {
        path: path + '/endPoint-new',
        name: 'endPoint-new',
        component: () => import('@views/top-bar/webhooks/create-or-update/Index')
    },
    {
        path: path + '/endPoint-update/:id',
        name: 'endPoint-update',
        component: () => import('@views/top-bar/webhooks/create-or-update/Index')
    },
    {
        path: path + '/endpoint-update/:id',
        name: 'endpoint-update',
        component: () => import('@views/top-bar/webhooks/create-or-update/Index')
    }
]