import Auth from "@/auth/auth";
import login from "@/auth/login";
import PasswordRecovery from "@/auth/password-recovery.vue";


export default {
  path: '/auth',
  component: Auth,
  children: [
    {
      path: 'login',
      name: 'Login',
      component: login,
    },
    {
      path: 'password-recovery',
      name: 'password-recovery',
      component: PasswordRecovery,
    },
  ]
};
