import {hydrate} from "@/utils/form";

export default {
  setPlaygrounds: (state, payload) => {
    state.playgrounds = payload;
  },
  setLoader: (state, payload) => {
    state.loader = payload;
  },
  setSelectedBlockPrices: (state, payload) => {
    state.selectedBlockPrices = payload;
  },
  setSelectedTimetables: (state, payload) => {
    state.selectedTimetables = payload;
  },
  setClubTimetables: (state, payload) => {
    state.clubTimetables = payload;
  },
  setBlockPricesTotalItems: (state, payload) => {
    state.blockPricesTotalItems = payload;
  },
  addBlockPrice: (state, payload) => {
    const findBlockPriceToAdd = state.selectedBlockPrices.find(blockPrice => blockPrice.id === payload.id);

    if ("undefined" === typeof findBlockPriceToAdd) {
      state.selectedBlockPrices.unshift(payload);
    }
  },
  updateBlockPrice: (state, payload) => {
    const findBlockPriceToUpdate = state.selectedBlockPrices.find(blockPrice => blockPrice.id === payload.id);

    if ("undefined" !== typeof findBlockPriceToUpdate) {
      state.selectedBlockPrices.splice(state.selectedBlockPrices.indexOf(findBlockPriceToUpdate), 1);
      state.selectedBlockPrices.unshift(payload);
    }
  },
  updateBlockPriceSingleSelection: (state, payload) => {
    const findBlockPriceToUpdate = state.selectedBlockPrices.find(blockPrice => blockPrice.id === payload.id);
    if ("undefined" !== typeof findBlockPriceToUpdate) {
      findBlockPriceToUpdate.singleSelection = true;
      for (const blockPrice of state.selectedBlockPrices) {
        if (blockPrice.id !== findBlockPriceToUpdate.id) {
          blockPrice.singleSelection = false;
        }
      }
    }
  },
  removeBlockPrice: (state, payload) => {
    const findBlockPriceToRemove = state.selectedBlockPrices.find(blockPrice => blockPrice.id === payload.id);

    if ("undefined" !== typeof findBlockPriceToRemove) {
      state.selectedBlockPrices.splice(state.selectedBlockPrices.indexOf(findBlockPriceToRemove), 1);
    }
  },
  addTimetable: (state, payload) => {
    const findTimetableToAdd = state.selectedTimetables.find(timetable => timetable.id === payload.id);

    if ("undefined" === typeof findTimetableToAdd) {
      state.selectedTimetables.unshift(payload);
    }
  },
  updateTimetable: (state, payload) => {
    const findTimetableToUpdate = state.selectedTimetables.find(timetable => timetable.id === payload.id);

    if ("undefined" !== typeof findTimetableToUpdate) {
      hydrate(findTimetableToUpdate, payload);
    }
  },
  removeTimetable: (state, payload) => {
    const findTimetableToRemove = state.selectedTimetables.find(timetable => timetable.id === payload.id);

    if ("undefined" !== typeof findTimetableToRemove) {
      state.selectedTimetables.splice(state.selectedTimetables.indexOf(findTimetableToRemove), 1);
    }
  },
}
