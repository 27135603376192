import {isNotUndefinedAndNotNull} from "@/utils/classes";

const tickets = localStorage.getItem('nextoreTickets');
const nextoreTickets = isNotUndefinedAndNotNull(tickets) ? JSON.parse(tickets) : [];

export default {
  sale: {
    products: [],
    payments: [],
    orderQuote: null
  },

  method: null,
  tapeValue: 0,
  rendu: 0,
  deposit: 0,
  creationDate: null,
  customer: null,
  booking: null,
  quoteItems: [],
  showModal: false,
  tickets: nextoreTickets,
  nextoreProducts: [],
}
