const path = 'subscriptions';

export default [
  {
    path: path,
    name: path,
    component: () => import('@views/subscriptions/Index'),
  },
  {
    path: path + '/new',
    name: 'subscription_new',
    component: () => import('@views/subscriptions/subscription-list/subscription-create-update/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id',
    name: 'subscription_update',
    component: () => import('@views/subscriptions/subscription-list/subscription-create-update/Index'),
  },
];
