import { _get, _post, _put, _delete, clubId } from "@api/doinsport/services/httpService";

export const QUOTE_URL = '/orders/quotes';


export const getQuotes = (itemsPerPage, page, search, filters = '') => _get(
  QUOTE_URL +
  '?club.id=' + clubId +
  '&order.archived=false' +
  '&itemsPerPage=' + itemsPerPage +
  '&page=' + page +
  '&search=' + search + filters
);

export const getQuoteDetails = (quoteId) => _get(
  `${QUOTE_URL}/${quoteId}`
);

export const getQuoteProducts = (orderId) => _get(
  `${orderId}/items`
);

export const editOrderQuoteItem = (id, data) => _put(
  `/orders/items/${id}`, data
);

export const createOrderQuoteItem = (data) => _post(
  `/orders/items`, data
);

export const deleteOrderQuoteItem = (id) => _delete(
  `/orders/items/${id}`
);


export const updateStatus = (quoteId, data) => _put(
  QUOTE_URL +
  '/' + quoteId, data
);

export const getClubQuote = () => _get(
  `/clubs/${clubId}/quote-configuration.json`
);

export const editClubQuote = (id, data) => _put(
  `/clubs/quote-configurations/${id}`, data
);

export const createOrderQuote = (client, quote, items) =>
  _post(
    '/orders' , { club: `/clubs/${clubId}`, quote, client: client ? `/clubs/clients/${client}` : null, items }
  )
;

export const updateOrderQuote = (id, data) =>
  _put(
    `/orders/quotes/${id}` , data
  )
;

export const editQuote = (id, data) => _put(
  `/orders/quotes/${id}`, data
);

export const getQuotesByClient = (itemsPerPage, page, filters = '', clientId='') => _get(
  QUOTE_URL +
  '?club.id=' + clubId +
  '&order.archived=false' +
  '&itemsPerPage=' + itemsPerPage +
  '&page=' + page +
  '&search=' + filters +
  '&client.id=' + clientId
)

export const cancelQuote = (id, type) => _put(
  `/orders/quotes/${id}/cancel?orderRefund=${type}`, {}
);

export const refuseQuote = (id, type) => _put(
  `/orders/quotes/${id}/refuse?orderRefund=${type}`, {}
);

export const deleteQuote = (id, type) => _delete(
  `/orders/quotes/${id}?orderRefund=${type}`
);