import {_get, _put} from "@api/doinsport/services/httpService";
import {ARRAY_BUFFER} from "@api/config";

const URL = 'orders'

export const getOrder = (id) => _get(id);

export const getPayment = (id) => _get(`/payments?order.id=${id}&status[]=succeeded`);

export const updateOrder = (id, data) => _put(`${URL}/${id}`, data);

export const cancelOrder = (id) => _put(URL + `/${id}`, {status: "canceled"});

export const getOrderItems = (id) => _get(URL + `/${id}/items?itemsPerPage=120&page=1`);

export const refundOrder = (id, refund = true) => _put(URL + `/${id}/cancel?refund=${refund}`, {});

export const exportOrderToPdf = (quoteId) => _get( `${URL}/quotes/${quoteId}/export.pdf`, ARRAY_BUFFER)
