import {cloneData, hydrate} from "@/utils/form";
import store from "@plugins/store";
import {URI_BLOCK_PRICE} from "@api/doinsport/services/timetable/blocks/price/price.api";
import {UTC_SERVER_DATE_TIME_FORMAT} from "@/utils/date";
import Vue from "vue";
import {toUtc} from "@/utils/timezone";
import {isNotUndefinedAndNotNull} from "@/utils/classes";

const $moment = require('moment');

export default class Booking {
  id = null;
  name = null;
  endAt = null;
  startAt = null;
  activity = null;
  category = null;
  nameManuallyUpdated = false;
  client = null;
  playgrounds = [];
  participants = [];
  paymentMethod = null;
  creationOrigin = null;
  playgroundOptions = [];
  timetableBlockPrice = null;
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object = null, extra = null) {
    if (object) {
      this.serialize(object);
    }

    if (extra.assoc) {
      this.timetableBlockPrice = `/${URI_BLOCK_PRICE}/${extra.blockPrice.id}`;
      this.userClub = store.getters["auth/currentUser"]['@id'];
      this.creationOrigin = 'administration';
      this.paymentMethod = 'on_the_spot';
    }
  }

  serialize(object) {
    hydrate(this, object);
  }

  static serializeBookingFromProduct(product, orderIri = null) {
    const booking = cloneData(product.booking);

    booking.name = !product.booking.nameManuallyUpdated ? product.client.firstName + ' ' + product.client.lastName : product.booking.name;

    if (orderIri) {
      booking.order = orderIri;
      booking.confirmed = true;
    }

    this.appendOptions(booking, product);
    this.appendParticipants(booking, product);
    this.formatDates(booking, product);

    return booking;
  }

  static appendOptions(booking, product) {
    if (null === booking.id) {
      booking.playgroundOptions = [];

      for (const option of product.options) {
        if (null !== option.id && option.checked) {
          booking.playgroundOptions.push({
            option: option[`@id`],
            quantity: option.quantity
          });
        }
      }
    } else {
      const selectedOptions = product.options.filter(el => el.checked && el.id);

      let bookingOptions = [];
      const options = cloneData(booking.playgroundOptions);

      for (const option of selectedOptions) {

        const alreadyExist = options.find(el => el.option['@id'] === option['@id']);

        if (isNotUndefinedAndNotNull(alreadyExist)) {
          bookingOptions.push({
            id: alreadyExist[`@id`],
            quantity: option.quantity
          });
        } else {
          bookingOptions.push({
            option: option[`@id`],
            quantity: option.quantity
          });
        }
      }

      booking.playgroundOptions = bookingOptions;
    }
  }

  static formatDates(booking, product) {
    const slot = product.block.startAt.split(':');

    const startAt = this.createBookingDate(product.slotDate, slot[0], slot[1]);
    const endAt = startAt.clone().add(product.blockPrice.duration, 'seconds');

    booking.startAt = toUtc(startAt)
      .tz(store.getters["auth/currentClub"].timezone)
      .utc()
      .format(UTC_SERVER_DATE_TIME_FORMAT)
    ;

    booking.endAt = toUtc(endAt)
      .tz(store.getters["auth/currentClub"].timezone)
      .utc()
      .format(UTC_SERVER_DATE_TIME_FORMAT)
    ;
  }

  static createBookingDate(date, hour, minute) {
    const timezone = store.getters["auth/currentClub"].timezone;
    const dateFormat = $moment.utc(date).tz(timezone);

    dateFormat.set({
      hour: hour,
      minute: minute,
      second: 0,
      millisecond: 0
    });

    dateFormat.toISOString();
    dateFormat.format();

    return dateFormat;
  }


  static appendParticipants(booking, product) {
    booking.participants = [];

    for (let i = 0; i < product.participants.length; i++) {
      const participant = product.participants[i];

      participant.clientEticket = isNotUndefinedAndNotNull(product.ticket) ? product.ticket['@id'] : null

      booking.participants.push({
        mainParticipant: participant.mainParticipant,
        client: participant.client,
        booking: booking.id ? `/clubs/bookings/${booking.id}` : null,
        category: participant.category,
        clientEticket: isNotUndefinedAndNotNull(product.ticket) ? product.ticket['@id'] : null,
      });
    }
  }
}
