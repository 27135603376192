const path = 'playground';

export default [
  {
    path: path,
    name: path,
    component: () => import('@views/playground/Index'),
    meta: {
      title: 'data.menu.playground.meta.title',
    }
  },
  {
    path: path + '/new',
    name: 'playground_new',
    component: () => import('@views/playground/new/Index'),
    meta: {
      title: 'data.menu.playground.meta.title',
    }
  },
  {
    path: path + '/parameters',
    name: 'playgrounds_parameters',
    component: () => import('@views/playground/parameters/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id',
    name: 'playground_update',
    component: () => import('@views/playground/new/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id' + '/config/step-1',
    name: 'playground_config-step1',
    component: () => import('@views/playground/steps/step-1/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id' + '/config/step-2',
    name: 'playground_config-step2',
    component: () => import('@views/playground/steps/step-2/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id' + '/config/step-3',
    name: 'playground_config-step3',
    component: () => import('@views/playground/steps/step-3/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id' + '/config/step-4',
    name: 'playground_config-step4',
    component: () => import('@views/playground/steps/step-4/Index'),
  },
];
