import {fromIriReferenceToId, hydrate} from "@/utils/form";
export const CUSTOMER_EVENTS = ['client.updated', 'client.created', 'client.deleted'];
export const BOOKING_EVENTS = ['booking.updated', 'booking.created', 'booking.deleted'];
export const ALL_EVENTS = [CUSTOMER_EVENTS, BOOKING_EVENTS];

export default class EndPoint {
  events = [];
  description = null;
  url = null;
  secretKey = null;
  enabled = true;
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object = null, options) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
    this.id = fromIriReferenceToId('/clubs/webhook-configurations/', object['@id']);
  }

}
