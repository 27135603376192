export const ARRAY_BUFFER = 'arraybuffer';
export const BINARY_ENCODE= 'binary';
export const PDF_CONTENT_TYPE = 'application/pdf';
export const JSON_CONTENT_TYPE = 'application/json';
export const BLOB_CONTENT_TYPE = 'blob';

export default {
  array_buffer: ARRAY_BUFFER,
  binary_encode: BINARY_ENCODE,
  pdf_contentType: PDF_CONTENT_TYPE,
  json_contentType: JSON_CONTENT_TYPE,
  blob_contentType: BLOB_CONTENT_TYPE,
}
