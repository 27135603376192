const path = 'caisse';

export default [
  {
    path: path,
    name: 'caisse_index',
    props: true,
    component: () => import('@views/caisse/Index'),
  },
  {
    path: path + '/booking',
    name: 'caisse_booking',
    component: () => import('@custom/caisse/ModalCaisse'),
  },
  {
    path: '/sales-caisse',
    name: 'sales_caisse',
    component: () => import('@views/caisse/sales/Index'),
  },

];
