import {toIntFixed} from "@/utils/string";
import {taxeList} from "@/constants/order/taxe-list";
import {YEAR_DASH_MONTH_DASH_DAY} from "@/utils/date";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {clubId} from "@api/doinsport/services/httpService";
import {createOrderQuote, createOrderQuoteItem, editOrderQuoteItem} from "@api/doinsport/services/quote/quote.api";
import {getOrderItems} from "@api/doinsport/services/orders/order.api";
import {fromIriReferenceToId} from "@/utils/form";
import {PAID_NEXTORE_PRODUCT_IDENTIFIER} from "@/constants/caisse/payment";

const moment = require('moment');

export default {
  createOrderQuote: (context) => {
    return new Promise((resolve) => {
      const customer = context.rootGetters['nextore/getCustomer'];
      const quoteConfiguration = context.rootGetters['quote/getQuoteConfiguration'];

      const quote = {
        client: customer ? customer.id : null,
        status: 'draft',
        expiresAt: moment(Date.now()).add(quoteConfiguration.defaultExpirationAsDay, 'd').format(YEAR_DASH_MONTH_DASH_DAY)
      }
      createOrderQuote(customer ? customer.id : null, quote, {})
        .then((response) => {
          context.commit('setOrderQuote', response.data);
          resolve(response.data);
        })
      ;
    });
  },
  setQuoteDetails: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('setQuote', payload);

      getOrderItems(fromIriReferenceToId('/orders/', payload.order['@id']))
        .then((response) => {
          context.commit('setQuoteItems', response.data['hydra:member']);
          context.commit('nextore/fromItemsToProducts',{items: response.data['hydra:member'], from: 'quote-details', order: payload.order} , {root: true});
        })
      ;
    });
  },
  createOrUpdateItemQuote: (context, product) => {
    return new Promise(() => {
      const taxRate = taxeList[product.taxeRate];
      let unitPrice = product.price;
      let quantity = product.quantity;
      let taxAmount = Math.round(((product.price) * quantity) / ((taxRate + 100) * taxRate) * 100);

      const item = {
        title: product.name,
        quantity: Math.round(product.quantity),
        unitPrice: toIntFixed(unitPrice),
        discount: null,
        taxValue: taxRate,
        taxAmount: taxAmount,
        nextoreProductId: product.id,
        order: context.state.orderQuote['@id'],
        club: `/clubs/${clubId}`
      }

      const findItem = context.state.quoteItems.find(el => el.nextoreProductId === product.id)

      if (isNotUndefinedAndNotNull(findItem)) {
        editOrderQuoteItem(findItem.id, item)
          .then((response) => {
            context.commit('updateQuoteItem', response.data);
          })
        ;
      } else {
        if (item.title !== PAID_NEXTORE_PRODUCT_IDENTIFIER) {
          createOrderQuoteItem(item)
            .then((response) => {
              product.orderItem = response.data;
              context.commit('addQuoteItem', response.data);
            })
          ;
        }
      }
    });
  }
}
