export default {
  setBlockPrices: (state, payload) => {
    state.blockPrices = payload;
  },
  setPriceCategories: (state, payload) => {
    state.priceCategories = payload;
  },
  setTmpBooking: (state, payload) => {
    state.tmpBooking = payload;
  },
  setBookingClient: (state, payload) => {
    state.client = payload;
  },
}
