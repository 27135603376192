const path = 'subscriptions/credits';

export default [
  {
    path: path,
    name: 'credit_list',
    component: () => import('@views/credits/Index'),
  },
  {
    path: path + '/new',
    name: 'credit_new',
    component: () => import('@views/subscriptions/credit-list/credit-create-update/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id',
    name: 'credit_update',
    component: () => import('@views/subscriptions/credit-list/credit-create-update/Index'),
  },
];
