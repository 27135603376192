import {randomString} from "@/utils/form";
import {editOrderQuoteItem} from "@api/doinsport/services/quote/quote.api";

export default {
  removeTicket: (context) => {
    context.commit('clearSale');
  },
  removeProduct: (context, payload) => {
    return new Promise((resolve) => {
      context.commit('removeProduct', payload);
      resolve(true);
    });
  },
  updateQuantity: (context, payload) => {
    context.commit('updateQuantity', payload);
  },
  updateProduct: (context, payload) => {
    return new Promise(resolve => {
      let state = context.state;
      const productSale = state.sale.products.find(el => el.id === payload.id);

      if (state.tapeValue !== 0 && state.tapeValue !== null) {
        productSale.nbArticle += state.tapeValue;
      } else {
        productSale.nbArticle++;
      }

      productSale.quantity++;

      resolve(productSale);
    });
  },
  updatePrice: (context, payload) => {
    context.commit('updatePrice', payload);
  },
  addPayment: (context, payload) => {
    return new Promise((resolve, reject) => {
      let amount = 0;
      let rejected = false;
      const state = context.state;

      state.sale.products.forEach(product => {
        if (product.isTicket) {
          if (state.customer === '' || state.customer === null) {
            rejected = true;
            return reject({customer: null});
          }
        }
        amount = amount + (product.price * product.quantity);
      });
      amount = getDiscountedAmount(amount, state.deposit);

      if (rejected === false) {
        state.sale.payments.forEach(payment => {
          amount = amount - payment.value;
        });

        if (parseFloat(state.tapeValue) > 0 && state.tapeValue >= amount) {
          payload.value = parseFloat(amount).toFixed(2);
          state.rendu = state.tapeValue - amount;
        } else {
          if (parseFloat(state.tapeValue) > 0) {
            payload.value = parseFloat(state.tapeValue).toFixed(2);
          } else {
            payload.value = parseFloat(amount).toFixed(2);
          }
          state.rendu = 0;
        }
        state.sale.payments.push(payload);
        state.tapeValue = 0;
      }
      return resolve(state.rendu);
    });
  },
  addProduct: (context, payload) => {
    return new Promise((resolve) => {
      payload.uniqId = randomString();
      context.commit('addProduct', payload);
      context.commit('setTapeValue', 0);
      resolve(payload.uniqId)
    });
  },
  setTapeValue: (context, payload) => {
    context.commit('setTapeValue', payload);
  },
  showModal: (context, payload) => {
    context.commit('setShowModal', payload);
  },
  clearSale: (context, payload) => {
    context.commit('clearSale', payload);
  },
  setRendu: (context, payload) => {
    context.commit('setRendu', payload);
  },
  setCustomer: (context, payload) => {
    context.commit('setCustomer', payload);
  },
  setBooking: (context, payload) => {
    context.commit('setBooking', payload);
  }
}

const getDiscountedAmount = (amount, deposit)  => amount - (amount * deposit / 100);

