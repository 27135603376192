const path = 'marketing';

export default [
  {
    path: path + '/email-campaign',
    name: 'email_campaign',
    component: () => import('@views/marketing/Index'),
  },
  {
    path: path + '/promotional-code',
    name: 'promotional_code_list',
    component: () => import('@views/marketing/promotional-code/Index'),
  },
  {
    path: path + '/promotional-code/new',
    name: 'promotional_code_new',
    component: () => import('@views/marketing/promotional-code/create-or-update/Index'),
  },
  {
    path: path + '/promotional-code/:id',
    name: 'promotional_code_update',
    component: () => import('@views/marketing/promotional-code/create-or-update/Index'),
  },
  {
    path: path + '/sms-campaign',
    name: 'sms_campaign',
    component: () => import('@views/marketing/Index'),
  },
  {
    path: path + '/push-campaign',
    name: 'push_campaign',
    component: () => import('@views/marketing/Index'),
  },
  {
    path: path + '/email/new',
    name: 'marketing_email_new',
    component: () => import('@views/marketing/content/create-or-update/EmailCampaign'),
  },
  {
    path: path + '/sms/new',
    name: 'marketing_sms_new',
    component: () => import('@views/marketing/content/create-or-update/SmsCampaign'),
  },
  {
    path: path + '/sms/' + 'update' + '/:id',
    name: 'marketing_sms_update',
    component: () => import('@views/marketing/content/create-or-update/SmsCampaign'),
  },
  {
    path: path + '/email/' + 'update' + '/:id',
    name: 'marketing_email_update',
    component: () => import('@views/marketing/content/create-or-update/EmailCampaign'),
  },
  {
    path: path + '/email/' + '/:id' + '/report',
    name: 'campaign_email_report',
    props: true,
    component: () => import('@views/marketing/content/report/Index'),
  },
  {
    path: path + '/sms/' + '/:id' + '/report',
    name: 'campaign_sms_report',
    props: true,
    component: () => import('@views/marketing/content/report/Index'),
  },
  {
    path: path + '/push/new',
    name: 'marketing_push_new',
    component: () => import('@views/marketing/content/create-or-update/PushCampaign'),
  },
  {
    path: path + '/push/' + 'update' + '/:id',
    name: 'marketing_push_update',
    component: () => import('@views/marketing/content/create-or-update/PushCampaign'),
  },
  {
    path: path + '/push/' + '/:id' + '/report',
    name: 'campaign_push_report',
    props: true,
    component: () => import('@views/marketing/content/report/Index'),
  },
];
