import axios from 'axios';
import {JSON_CONTENT_TYPE} from "@api/config";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": JSON_CONTENT_TYPE,
  }
});

export default httpClient;
