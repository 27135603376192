export default {
  getProducts: (state) => {
    return state.sale.products;
  },
  getSale: (state) => {
    return state.sale;
  },
  getDeposit : (state) => state.deposit,
  getCustomer: (state) => {
    return state.customer;
  },
  getBooking: (state) => {
    return state.booking;
  },
  getQuoteItems: (state) => {
    return state.quoteItems;
  },
  showModal: (state) => {
    return state.showModal;
  },
  getRestToPay: (state) => {
    let total = 0;

    for (const product of state.sale.products) {
      if (product.price) {
        total = total + parseFloat((parseFloat(product.price) * product.quantity));
      }
    }

    total = total - total * state.deposit / 100;

    let sum = 0;
    for (const payment of state.sale.payments) {
      sum = sum + parseFloat(payment.value);
    }

    return (total > sum) ? (total - sum) : 0
  },
  getTotalPrices: (state) => {
    let sum = 0;
    for (const product of state.sale.products) {
      sum = sum + parseFloat((parseFloat(product.price) * product.quantity));
    }
    return sum;
  },
  getTotalPayments: (state) => {
    let sum = 0;
    for (const payment of state.sale.payments) {
      sum = sum + parseFloat(payment.value);
    }
    return sum;
  },
  getPayments: (state) => {
    return state.sale.payments;
  },
  getMethod: (state) => {
    return state.method;
  },
  getTapeValue: (state) => {
    return state.tapeValue;
  },
  getRendu: (state) => {
    return state.rendu;
  },
  getTickets: (state) => {
    return state.tickets;
  }
}
