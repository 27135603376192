import {formatRegisters} from "@/utils/format";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {getAllOpenRegistries, getOpenRegistriesByUserId} from "@api/nextore/services/register-details.api";
import {getSalesHistory} from "@api/nextore/services/sale.api";

const moment = require('moment');

export default {
  getAllActiveRegisters: ({commit}) => {
    return new Promise((resolve, reject) => {
      getAllOpenRegistries()
        .then((response)=> {
          if (Object.keys(response.data).length > 0) {
            commit('setAllActiveRegisters', response.data);
            resolve(response.data);
          } else {
            reject(null)
          }
        })
    });
  },
  checkActiveRegister: ({commit, getters, state}) => {
    return new Promise((resolve => {
      let registers = [];

      getOpenRegistriesByUserId(state.session.id)
        .then((response) => {
          if (null !== response.data) {
            registers = formatRegisters(response.data);
            if (state.register) {
              const register = registers.find(el => el.id === state.register.id);

              if (isNotUndefinedAndNotNull(register)) {
                resolve({
                  active: true,
                  closed: false,
                  expired: (moment.utc().diff(moment(register.date_utc), 'hours') > 24),
                });
              } else {
                let expired = false;
                let closed = true;
                let active = false;

                if (registers.length > 0) {
                  commit('setActiveRegisters', registers);
                  commit('updateRegister', registers[0]);
                  closed = false;
                  active = true;
                  expired = (moment.utc().diff(moment(state.register.date_utc), 'hours') > 24);
                } else {
                  commit('setDisplayClosedRegisterModal', true);
                  commit('updateRegister', {});
                }

                resolve({
                  active: active,
                  closed: closed,
                  expired: expired,
                });
              }
            } else {
              if (registers.length > 0) {
                commit('setActiveRegisters', registers);
                commit('updateRegister', registers[0]);

                resolve({
                  active: true,
                  closed: false,
                  expired: (moment.utc().diff(moment(register.date_utc), 'hours') > 24)
                })
              }
            }
          } else {
            commit('updateRegister', {});
            resolve({
              active: false,
              closed: false,
              expired: false,
            });
          }
        })
    }));
  },

  getHistorySale: ({state}) => {
    return new Promise((resolve => {
      getSalesHistory(state.register.id)
        .then((response) => {
          if (null !== response.data) {
            if (state.register) {
              resolve(response);
            }
          }
        })
    }));
  },

}
