const path = '/tickets';

export default [
  {
    path: path,
    name: 'tickets',
    component: () => import('@views/tickets/type/Index'),
  },
  {
    path: path + '/tracking',
    name: 'tracking-tickets',
    component: () => import('@views/tickets/tracking/Index'),
  },
  {
    path: path + '/new',
    name: 'tickets_new',
    component: () => import('@views/tickets/type/create-or-update/Index'),
  },
  {
    path: path + '/' + 'update' + '/:id',
    name: 'tickets_update',
    component: () => import('@views/tickets/type/create-or-update/Index'),
  },
];
