const moment = require('moment');
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import store from "@plugins/store";

export default {
  setBlockPrices: (state, payload) => {
    state.blockPrices = payload;
  },
  setTickets: (state, payload) => {
    state.tickets = payload;
  },
  saveLastTicket: (state, payload) => {
    state.lastTicket = payload;
  },
  setParticipantCategories: (state, payload) => {
    state.participantCategories = payload;
  },
  setPlaygrounds: (state, payload) => {
    state.playgrounds = payload;
  },
  setDisplayExpiredRegisterModal: (state, payload) => {
    state.displayExpiredRegisterModal = payload;
  },
  setDisplayNoRegisterModal: (state, payload) => {
    state.displayNoRegisterModal = payload;
  },
  setDisplayNoCashRegisterModal: (state, payload) => {
    state.displayNoCashRegisterModal = payload;
  },
  setDisplayClosedRegisterModal: (state, payload) => {
    state.displayNoCashRegisterModal = payload;
  },
  setDisplayCashRegistriesModal: (state, payload) => {
    state.displayCashRegistriesModal = payload;
  },
  initSession: (state, payload) => {
    state.session = {};

    localStorage.setItem('activeSessions', JSON.stringify([]));
    localStorage.setItem('nextoreSession', JSON.stringify(null));
    localStorage.setItem('activeRegisters', JSON.stringify([]));

    if (payload !== {}) {
      const findActiveSession = state.activeSessions.find(el => el.id === payload.id);

      if (isNotUndefinedAndNotNull(findActiveSession)) {
        state.activeSessions.splice(state.activeSessions.indexOf(findActiveSession), 1);

        localStorage.setItem('activeSessions', JSON.stringify(state.activeSessions));
      }
    }
  },
  initRegister: (state) => {
    state.register = {};
  },

  setAllActiveRegisters: (state, payload) => {
    state.allActiveRegisters = payload;
  },
  setActiveRegisters: (state, registers) => {
    state.activeRegisters = registers;

    localStorage.setItem('activeRegisters', JSON.stringify(state.activeRegisters));
  },
  closeRegister: (state, payload) => {
    if (payload !== {}) {
      const findRegister = state.activeRegisters.find(el => el.id === payload.id);

      if (isNotUndefinedAndNotNull(findRegister)) {
        state.activeRegisters.splice(state.activeRegisters.indexOf(findRegister), 1);

        localStorage.setItem('activeRegisters', JSON.stringify(state.activeRegisters));
      }
    }

    state.register = {};
    localStorage.setItem('nextoreRegister', JSON.stringify(state.register));
  },
  setSession: (state, payload) => {
    state.session = payload;
    state.session.club = JSON.parse(localStorage.getItem('selectedClub'));

    if (state.activeSessions.length > 0) {
      let lastActiveSession = state.activeSessions.find(el => el.club === state.session.club && el.id === state.session.id);

      if (isNotUndefinedAndNotNull(lastActiveSession)) {
        state.activeSessions[state.activeSessions.indexOf(lastActiveSession)] = state.session;
      } else {
        state.activeSessions.push(state.session);
      }
    } else {
      state.activeSessions.push(state.session);
    }

    localStorage.setItem('activeSessions', JSON.stringify(state.activeSessions));
    localStorage.setItem('nextoreSession', JSON.stringify(state.session));
    store.commit('cashRegistry/activateRegister');
  },
  checkRegisterValidity: (state) => {
    if (state.register.id) {
      if (moment.utc().diff(moment(state.register.date_utc), 'hours') > 24) {
        store.commit('cashRegistry/setDisplayExpiredRegisterModal', true);
      }
    }
  },
  updateRegister: (state, payload) => {
    state.register = payload;
    localStorage.setItem('nextoreRegister', JSON.stringify(state.register));
  },
  activateSession: (state, payload) => {
    state.session = payload;
  },
  activateRegister: (state) => {
    const findActiveRegister = state.activeRegisters.find(el => el.club === state.session.club && el.user_id === state.session.id);

    if (isNotUndefinedAndNotNull(findActiveRegister)) {
      if (findActiveRegister.status === 'open' && moment.utc().diff(moment(findActiveRegister.date_utc), 'hours') <= 24) {
        store.commit('cashRegistry/setRegister', findActiveRegister);
      } else {
        store.commit('cashRegistry/setDisplayExpiredRegisterModal', true);
      }
    } else {
      state.register = {};
      localStorage.setItem('nextoreRegister', JSON.stringify(state.register));
    }
  },
  setRegister: (state, payload) => {
    state.register = payload;
    state.register.club = JSON.parse(localStorage.getItem('selectedClub'));

    localStorage.setItem('nextoreRegister', JSON.stringify(state.register));

    if (state.activeRegisters.length > 0) {
      let lastActiveSession = state.activeRegisters.find(el => el.club === state.register.club && el.id === state.register.id);

      if (isNotUndefinedAndNotNull(lastActiveSession)) {
        state.activeRegisters[state.activeRegisters.indexOf(lastActiveSession)] = state.register;
      } else {
        state.activeRegisters.push(state.register);
      }
    } else {
      state.activeRegisters.push(state.register);
    }

    localStorage.setItem('activeRegisters', JSON.stringify(state.activeRegisters));
  },
  switchRegister: (state, payload) => {
    state.register = payload;
    state.register.club = JSON.parse(localStorage.getItem('selectedClub'));

    localStorage.setItem('nextoreRegister', JSON.stringify(state.register));

    if (state.activeRegisters.length > 0) {
      let lastActiveSession = state.activeRegisters.find(el => el.club === state.register.club && el.id === state.register.id);

      if (isNotUndefinedAndNotNull(lastActiveSession)) {
        state.activeRegisters[state.activeRegisters.indexOf(lastActiveSession)] = state.register;
      } else {
        state.activeRegisters.push(state.register)
      }
    } else {
      state.activeRegisters.push(state.register);
    }

    localStorage.setItem('activeRegisters', JSON.stringify(state.activeRegisters));
  },
  setSessions: (state, payload) => {
    state.sessions = payload;
  },
  setDisplaySessionsModal: (state, payload) => {
    state.displaySessionsModal = payload;
  },
}
