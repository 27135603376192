const path = 'access-control';

export default [
  {
    path: path,
    name: 'access-control',
    component: () => import('@views/top-bar/access-control/Index'),
  },
  {
    path: path + '/settings',
    name: 'enzocard-settings',
    component: () => import('@views/top-bar/access-control/enzocard/Index'),
  },
];
